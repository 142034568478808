import React from 'react';
import styled from 'styled-components';
import NewMarketForm from '../components/NewMarketForm';

const Wrapper = styled.div`
  width: 100%;
  margin: 24px auto;
`;

export default function ListNewMarketPage() {
  return (
    <Wrapper>
      <NewMarketForm />
    </Wrapper>
  );
}
