import BN from 'bn.js';
import React from 'react';
import styled from 'styled-components';
import { useMarket, useTrades } from '../utils/markets';
import { RecentTrade } from '../utils/types';
import { getDecimalCount } from '../utils/utils';

// TODO This is very similar to new orderbook component, can be reused

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
`;

const Title = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  user-select: none;
  color: var(--color-text-light);
  max-height: 44px;
  min-height: 44px;

  @media (max-width: 1024px) {
    scrollbar-width: none;
    font-size: 24px;
    line-height: 30px;
    padding: 0 20px 0 8px;
    max-height: 72px;
    min-height: 72px;
    justify-content: center;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top: var(--general-border);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 28px;
`;

const HeaderText = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a1a5c1;
  justify-content: space-between;
  padding: 10px 0;
`;

const SizeHeader = styled(HeaderText)`
  text-align: right;
  flex: 0 0 32%;
  padding-right: 4px;
`;

const PriceHeader = styled(HeaderText)`
  text-align: right;
  flex: 0 0 24%;
`;

const TimeHeader = styled(HeaderText)`
  text-align: right;
  flex: 0 0 28%;
  padding-right: 20px;
`;

const TokenHeader = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 14px;
  color: #e3e5f1;
  user-select: none;
  display: inline-flex;
  background-color: var(--color-layer-lighter);
  border-radius: 2px;
  padding: 1px 4px 1px 5px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  padding: 0 3px 0 4px;
  margin-top: -2px;
  margin-bottom: -2px;
  align-items: center;
  font: #c3c2d4;

  &,
  & svg,
  & svg * {
    transition: all 0.15s ease-in-out !important;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: Relative Mono;
  font-weight: 400;
  -webkit-font-feature-settings: 'zero' 1;
  font-feature-settings: 'zero' 1;
  font-size: 12px;
  line-height: 16px;
  color: #c3c2d4;
  text-align: right;
  flex: 1 1 32%;
  z-index: 2;
  border-bottom: var(--general-border);
`;

const TradeRowContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 2px;
  height: 20px;
  min-height: 20px;
  cursor: pointer;
`;

const TradeContentContainer = styled.div`
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TradeContent = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  flex: 0 0 40%;
  text-align: right;
  text-transform: uppercase;
`;

const TradeSizeContent = styled(TradeContent)<Partial<TradeRowProps>>`
  flex: 0 0 32%;
  padding-right: 8px;
  color: ${(props: React.PropsWithChildren<Partial<TradeRowProps>>) =>
    props.side === 'buy' ? 'var(--color-green)' : 'var(--color-red)'};
`;

const TradePriceContent = styled(TradeContent)`
  flex: 0 0 12%;
`;

const TradeTimeContent = styled(TradeContent)`
  flex: 0 0 32%;
  color: var(--color-text-dark);
`;

interface TradeRowProps {
  side: string;
  price: number;
  size: number;
  timestamp: BN;
}

const TradeRow = (props: TradeRowProps) => {
  const { market } = useMarket();

  let formattedSize =
    market?.minOrderSize && !isNaN(props.size)
      ? Number(props.size).toFixed(getDecimalCount(market.minOrderSize) + 1)
      : props.size;

  let formattedPrice =
    market?.tickSize && !isNaN(props.price)
      ? Number(props.price).toFixed(getDecimalCount(market.tickSize) + 1)
      : props.price;

  return (
    <TradeRowContainer>
      <TradeContentContainer>
        <TradeSizeContent side={props.side}>{formattedSize}</TradeSizeContent>
        <TradePriceContent>{formattedPrice}</TradePriceContent>
        <TradeTimeContent>
          {props.timestamp &&
            new Date(props.timestamp.toNumber() * 1000).toLocaleTimeString()}
        </TradeTimeContent>
      </TradeContentContainer>
    </TradeRowContainer>
  );
};

const RecentTrades = () => {
  const { baseCurrency, quoteCurrency } = useMarket();
  // TODO Replace 24h trades data
  const trades: Array<RecentTrade> | null = useTrades();

  return (
    <Container>
      <Title>Recent Market Trades</Title>
      <Body>
        <Header>
          <SizeHeader>
            Size <TokenHeader>{baseCurrency}</TokenHeader>
          </SizeHeader>
          <PriceHeader>
            Price <TokenHeader>{quoteCurrency}</TokenHeader>
          </PriceHeader>
          <TimeHeader>Time</TimeHeader>
        </Header>
        <Content>
          {trades?.map((trade) => (
            <TradeRow
              key={`${trade.orderId}-${trade.side}-${trade.price}-${trade.size}`}
              price={trade.price}
              size={trade.size}
              side={trade.side}
              timestamp={trade.timestamp}
            />
          ))}
        </Content>
      </Body>
    </Container>
  );
};

export default RecentTrades;
