import { PublicKey } from '@solana/web3.js';
import Markets from './markets.json';
import TokenMints from './token_mints.json';

export const PROGRAM_LAYOUT_VERSIONS = {
  CwuLw1CfReCaa3De5pfeJCkUF44V3ajqurp1pFdDNKcR: 3,
};

export function getLayoutVersion(programId: PublicKey) {
  return PROGRAM_LAYOUT_VERSIONS[programId.toString()] || 3;
}

export const TOKEN_MINTS: Array<{
  address: PublicKey;
  name: string;
}> = TokenMints.map((mint) => {
  return {
    address: new PublicKey(mint.address),
    name: mint.name,
  };
});

export const MARKETS: Array<{
  address: PublicKey;
  name: string;
  programId: PublicKey;
  deprecated: boolean;
}> = Markets.map((market) => {
  return {
    address: new PublicKey(market.address),
    name: market.name,
    programId: new PublicKey(market.programId),
    deprecated: market.deprecated,
  };
});
