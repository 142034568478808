import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useFills, useMarket } from '../../utils/markets';
import { EmptyContent } from '../common/EmptyContent';

interface Props extends HTMLAttributes<HTMLDivElement> {
  width?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #2d2d3d;
  position: relative;
  background-color: var(--color-layer-base);
  width: 100%;
  height: 100%;
`;

const Headers = styled.div`
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  background-color: var(--color-layer-base);
  padding: 0 16px;
  height: 40px;
  min-height: 40px;
  color: var(--color-text-dark);
  border-bottom: 1px solid var(--color-border-grey);
  letter-spacing: 0;
`;

const Header = styled.div<{ width: string }>`
  display: flex;
  height: 100%;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${(props: React.PropsWithChildren<Props>) => props.width};
  width: ${(props: React.PropsWithChildren<Props>) => props.width};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  overflow: scroll;
`;

const Row = styled.div`
  display: flex;
  position: relative;
  height: 48px;
  min-height: 48px;
  color: var(--color-text-base);
  border-bottom: var(--general-border);
  padding: 0 16px;
  box-sizing: border-box;
  width: max-content;
  min-width: 100%;

  &:last-child {
    border-bottom: none;
  }
`;

const Cell = styled.div<Props>`
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${(props: React.PropsWithChildren<Props>) => props.width};
  width: ${(props: React.PropsWithChildren<Props>) => props.width};
`;

const RecentTradeHistory = () => {
  const fills = useFills();

  const { quoteCurrency } = useMarket();

  const tableConfig = [
    {
      title: 'Market',
      key: 'marketName',
      width: '16%',
      index: 0,
    },
    {
      title: 'Side',
      key: 'side',
      width: '16%',
      index: 1,
    },
    {
      title: 'Size',
      key: 'size',
      width: '16%',
      index: 2,
    },
    {
      title: 'Price',
      key: 'price',
      width: '16%',
      index: 3,
    },
    {
      title: 'Liquidity',
      key: 'liquidity',
      width: '16%',
      index: 4,
    },
    {
      title: quoteCurrency ? `Fees (${quoteCurrency})` : 'Fees',
      key: 'feeCost',
      width: '16%',
      index: 5,
    },
  ];

  const headers = tableConfig
    .sort((a, b) => a.index - b.index)
    .map((header) => (
      <Header key={header.key} width={header.width}>
        {header.title}
      </Header>
    ));

  const body = (fills || [])
    .map((fill) => ({
      ...fill,
      key: `${fill.orderId}${fill.side}`,
      liquidity: fill.eventFlags.maker ? 'Maker' : 'Taker',
    }))
    .map((fill, index) => {
      let content: Array<JSX.Element> = [];
      for (const header of tableConfig) {
        content.push(
          <Cell width={header.width} key={`${header.key}-${header.index}`}>
            {fill[header.key]}
          </Cell>,
        );
      }
      content.sort((a, b) => a.props.index - b.props.index);
      return <Row key={index}>{content}</Row>;
    });

  const content = fills ? (
    <Container>
      <Headers>{headers}</Headers>
      <Body>{body}</Body>
    </Container>
  ) : (
    <EmptyContent alignAbsoluteCenter={false} text={'No recent trades'} />
  );

  return content;
};

export default RecentTradeHistory;
