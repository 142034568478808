import React from 'react';
import { useWallet } from '../utils/wallet';
import styled from 'styled-components';

const Container = styled.div`
  justify-content: center;
  min-width: 168px;
  & > button {
    padding: 0 14px;
  }
`;

const ConnectButton2 = styled.button`
  width: 92px;
  height: 20px;

  line-height: 20px;

  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 10px;
  gap: 10px;

  width: 112px;
  height: 36px;

  background: #646ec4;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const ConnectButton = styled.button`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: 'zero' 0;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-background-color, var(--color-layer-lighter));
  color: var(--button-text-color, var(--color-text-base));
  cursor: pointer;
  border: none;
  user-select: cursor;
  white-space: nowrap;
  width: 100%;
  height: 32px;
  min-height: 32px;
  border-radius: 8px;
  padding: 0px 16px;
  --button-background-color: var(--color-purple);
  --button-active-background-color: rgba(89, 115, 254, 0.8);
  --button-text-color: var(--color-text-light);
`;

export default function WalletConnect() {
  const { connected, connect, disconnect } = useWallet();

  return (
    <Container>
      <ConnectButton onClick={connected ? disconnect : connect}>
        {connected ? 'Disconnect Wallet' : 'Connect Wallet'}
      </ConnectButton>
    </Container>
  );
}
