import React, { HTMLAttributes, useState, useMemo } from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';
import styled from 'styled-components';
import Link from './Link';
import { Dropdown, DropdownPositions } from './common/Dropdown';
import { DropdownTextItem } from './common/DropdownTextItem';
import { useConnectionConfig } from '../utils/connection';
import { EndpointInfo } from '../utils/types';
import WalletConnect from './WalletConnect';
import { getTradePageUrl } from '../utils/markets';

const Container = styled.div`
  grid-area: Header;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #a1a5c1;
  background-color: var(--color-layer-base);
  box-shadow: inset 0 -1px #2d2d3d;
  --logo-width: 56px;
  --sidebar-width: 324px;
  display: grid;
  grid-template:
    'Logo Nav NavLanguage NavAfter' 48px / var(--logo-width)
    auto 1fr auto;
  align-items: stretch;
  position: relative;
  max-width: 100vw;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  @media (max-width: 1024px) {
    & {
      display: none;
    }
  }
`;

const LogoContainer = styled.a`
  grid-area: Logo;
  display: flex;

  & > svg {
    width: 22px;
    height: 22px;
    margin: auto;
  }

  &:after {
    content: '';
    height: 24px;
    border-right: var(--general-border);
    align-self: center;
  }
`;

const Nav = styled.div`
  grid-area: Nav;
  display: flex;
  align-items: center;
  overflow-x: auto;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    width: 12px;
    background: linear-gradient(
      90deg,
      var(--color-layer-base),
      rgba(28, 28, 40, 0) 95%
    );
  }
  &:after {
    content: '';
    position: absolute;
    top: 8px;
    bottom: 8px;
    width: 6px;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(28, 28, 40, 0),
      var(--color-layer-base) 95%
    );
  }

  @media (max-width: 768px) {
    & {
      display: none;
    }
  }
`;

const NavInner = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 0 3px 0 8px;
`;

const NavLinkDiv = styled.div<Props>`
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.15s ease-in-out !important;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  align-items: center;
  padding: 6px 12px;
  margin: 0 3px;
  border-radius: 8px;

  & svg,
  & svg * {
    transition: all 0.15s ease-in-out !important;
  }

  background: ${(props: React.PropsWithChildren<Props>) =>
    props.active ? 'var(--color-layer-darker)' : 'inherit'};
  color: ${(props: React.PropsWithChildren<Props>) =>
    props.active ? '#ffffff' : 'inherit'};

  &:hover {
    background-color: ${(props: React.PropsWithChildren<Props>) =>
      props.active ? 'var(--color-layer-darker)' : 'inherit'};
    color: ${(props: React.PropsWithChildren<Props>) =>
      props.active ? '#ffffff' : 'var(--color-text-light)'};
  }
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 48px;
  cursor: pointer;

  &:hover > ${NavLinkDiv} {
    background-color: var(--color-layer-lighter);
  }
`;

const NavLanguageContainer = styled.div`
  grid-area: NavLanguage / NavLanguage / NavLanguage / NavLanguage;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: var(--page-header-height);
`;

const NavAfter = styled.div`
  grid-area: NavAfter;
  &,
  & > * {
    display: flex;
    align-items: center;
  }
  & > :not(:first-child):before {
    content: '';
    height: 24px;
    border-left: var(--general-border);
  }
  & > * > * {
    margin: 0 6px;
  }
`;

const Header = () => {
  const { endpointInfo, availableEndpoints, setEndpoint } =
    useConnectionConfig();
  const TRADE_TAB = 'trade';
  const ADD_MARKET_TAB = 'add_market';
  const ABOUT_TAB = 'about';
  const [currentTab, setCurrentTab] = useState(TRADE_TAB);

  const _changeTabs = (e) => {
    const changeTab = e.currentTarget.id;
    setCurrentTab(changeTab);
  };

  const onNetworkChange = (endpoint: string): void => {
    setEndpoint(endpoint);
  };

  const onLanguageChange = (language: string): void => {};

  const languageList = ['English', 'Vietnamese'];

  const languageDropdownHeader = <>{languageList[0]}</>;

  const languageDropdownBody = (
    <>
      {languageList.map((language: string) => (
        <DropdownTextItem
          onClick={() => onLanguageChange(language)}
          text={language}
          key={language}
        >
          {language}
        </DropdownTextItem>
      ))}
    </>
  );

  const networkDropdownHeader = <>{endpointInfo?.name}</>;

  const networkDropdownBody = (
    <>
      {availableEndpoints.map(({ name, endpoint }: EndpointInfo) => (
        <DropdownTextItem
          onClick={() => onNetworkChange(endpoint)}
          active={endpoint === endpointInfo?.endpoint}
          text={name}
          key={endpoint}
        >
          {name}
        </DropdownTextItem>
      ))}
    </>
  );

  const tradePageUrl = useMemo(() => getTradePageUrl(), []);

  return (
    <Container>
      <LogoContainer href={tradePageUrl}>
        <Logo />
      </LogoContainer>
      <Nav>
        <NavInner>
          <NavLinkItem
            id={TRADE_TAB}
            text="Trade"
            to={tradePageUrl}
            onClick={_changeTabs}
            active={currentTab === TRADE_TAB}
          />
          <NavLinkItem
            id={ADD_MARKET_TAB}
            text="Add Market"
            to={'/list-new-market'}
            onClick={_changeTabs}
            active={currentTab === ADD_MARKET_TAB}
          />
          <NavLinkItem
            id={ABOUT_TAB}
            text="About"
            to={getTradePageUrl()}
            onClick={_changeTabs}
            active={currentTab === ABOUT_TAB}
          />
        </NavInner>
      </Nav>
      <NavLanguageContainer>
        <Dropdown
          body={languageDropdownBody}
          header={languageDropdownHeader}
          horizontalPosition={DropdownPositions.Left}
          shouldCloseDropdownOnClickOutside={true}
        />
      </NavLanguageContainer>
      <NavAfter>
        <Dropdown
          body={networkDropdownBody}
          header={networkDropdownHeader}
          horizontalPosition={DropdownPositions.Left}
          shouldCloseDropdownOnClickOutside={true}
        />
        <WalletConnect />
      </NavAfter>
    </Container>
  );
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  onClick?: any;
}

interface NavLinkItemProps extends Props {
  text: string;
  to: string;
}

const NavLinkItem: React.FC<NavLinkItemProps> = (props) => {
  const { to, text, onClick, active, ...restProps } = props;

  return (
    <NavLink to={to}>
      <NavLinkDiv onClick={onClick} active={active} {...restProps}>
        {text}
      </NavLinkDiv>
    </NavLink>
  );
};

export default Header;
