import React, { useRef, useEffect, useState, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useMarket, useMarkPrice, useOrderbook } from '../utils/markets';
import { useInterval } from '../utils/useInterval';
import { getDecimalCount, isEqual } from '../utils/utils';
import PriceFormat from './common/PriceFormat';

interface Props extends HTMLAttributes<HTMLDivElement> {
  bgcolor: string;
  width: string;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
`;

const Title = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  user-select: none;
  color: #e3e5f1;
  max-height: 44px;
  min-height: 44px;

  @media (max-width: 1024px) {
    scrollbar-width: none;
    font-size: 24px;
    line-height: 30px;
    padding: 0 20px 0 8px;
    max-height: 72px;
    min-height: 72px;
    justify-content: center;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top: var(--general-border);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 28px;
`;

const HeaderText = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a1a5c1;
  justify-content: space-between;
  padding: 10px 0;
`;

const SizeHeader = styled(HeaderText)`
  text-align: right;
  flex: 0 0 32%;
  padding-right: 4px;
`;

const PriceHeader = styled(HeaderText)`
  text-align: right;
  flex: 0 0 32%;
  padding-right: 22px;
`;

const TokenHeader = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 14px;
  color: #e3e5f1;
  user-select: none;
  display: inline-flex;
  background-color: var(--color-layer-lighter);
  border-radius: 2px;
  padding: 1px 4px 1px 5px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  padding: 0 3px 0 4px;
  margin-top: -2px;
  margin-bottom: -2px;
  align-items: center;
  font: #c3c2d4;

  &,
  & svg,
  & svg * {
    transition: all 0.15s ease-in-out !important;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentTable = styled.div`
  text-align: right;
  flex: 1 1 32%;
  padding-right: 12px;
  z-index: 2;
`;

const AskTable = styled(ContentTable)``;
const BidTable = styled(ContentTable)``;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 2px;
  height: 20px;
  min-height: 20px;
  cursor: pointer;
`;

const OrderRowLine = styled.div`
  position: absolute;
  z-index: 0;
  opacity: 0.3;
  background-color: ${(props: React.PropsWithChildren<Props>) => props.bgcolor};
  width: ${(props: React.PropsWithChildren<Props>) => props.width};
  height: 18px;
  right: 0;
`;

const RowContent = styled.div`
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;
`;

const OrderContent = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  flex: 0 0 40%;
  text-align: right;
  text-transform: uppercase;
`;

const OrderSizeContent = styled(OrderContent)`
  text-align: right;
  flex: 0 0 32%;
  padding-right: 12px;
  z-index: 2;
`;

const OrderPriceContent = styled(OrderContent)`
  text-align: right;
  flex: 0 0 28%;
`;

const MarkPrice = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-base);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: default;
  border-top: var(--general-border);
  border-bottom: var(--general-border);
  flex: 0 0 10%;
`;

interface DisplayData {
  price: number;
  size: number;
  cumulativeSize: number;
  sizePercent: number;
}

const Orderbook = ({ depth = 7, onPrice, onSize }) => {
  const markPrice = useMarkPrice();
  const [orderbook] = useOrderbook();
  const { baseCurrency, quoteCurrency } = useMarket();

  const currentData = useRef({
    bids: new Array<Array<number>>(),
    asks: new Array<Array<number>>(),
  });
  const lastData = useRef({
    bids: new Array<Array<number>>(),
    asks: new Array<Array<number>>(),
  });

  const [displayData, setDisplayData] = useState({
    bids: new Array<DisplayData>(),
    asks: new Array<DisplayData>(),
  });

  const getCumulativeOrderbookSide = (
    orders: Array<Array<number>>,
    totalSize: number,
    backwards = false,
  ): Array<DisplayData> => {
    let cumulative = orders
      .slice(0, depth)
      .reduce(
        (
          cumulative: Array<DisplayData>,
          [price, size]: Array<number>,
          i: number,
        ) => {
          const cumulativeSize =
            (cumulative[i - 1]?.cumulativeSize || 0) + size;
          cumulative.push({
            price,
            size,
            cumulativeSize,
            sizePercent: Math.round((cumulativeSize / (totalSize || 1)) * 100),
          });
          return cumulative;
        },
        [],
      );

    if (backwards) {
      cumulative = cumulative.reverse();
    }
    return cumulative;
  };

  useInterval(() => {
    if (
      !currentData.current ||
      JSON.stringify(currentData.current) !== JSON.stringify(lastData.current)
    ) {
      let bids = orderbook?.bids || [];
      let asks = orderbook?.asks || [];

      let sum = (total: number, [, size]: Array<number>, index: number) =>
        index < depth ? total + size : total;
      let totalSize = bids.reduce(sum, 0) + asks.reduce(sum, 0);

      let bidsToDisplay = getCumulativeOrderbookSide(bids, totalSize, false);
      let asksToDisplay = getCumulativeOrderbookSide(asks, totalSize, true);

      currentData.current = {
        bids: orderbook?.bids,
        asks: orderbook?.asks,
      };

      setDisplayData({ bids: bidsToDisplay, asks: asksToDisplay });
    }
  }, 250);

  useEffect(() => {
    lastData.current = {
      bids: orderbook?.bids,
      asks: orderbook?.asks,
    };
  }, [orderbook]);

  return (
    <Container>
      <Title>Orderbook</Title>
      <Body>
        <Header>
          <SizeHeader>
            Size <TokenHeader>{baseCurrency}</TokenHeader>
          </SizeHeader>
          <PriceHeader>
            Price <TokenHeader>{quoteCurrency}</TokenHeader>
          </PriceHeader>
        </Header>
        <Content>
          <AskTable>
            {displayData?.asks.map((ask) => (
              <OrderRow
                key={ask.price}
                price={ask.price}
                size={ask.size}
                side={'sell'}
                sizePercent={ask.sizePercent}
                onPriceClick={() => onPrice(ask.price)}
                onSizeClick={() => onSize(ask.size)}
              />
            ))}
          </AskTable>
          <MarkPrice>
            <PriceFormat value={markPrice!!} color={'#3fb68b'} />
          </MarkPrice>
          <BidTable>
            {displayData?.bids.map((bid) => (
              <OrderRow
                key={bid.price}
                price={bid.price}
                size={bid.size}
                side={'buy'}
                sizePercent={bid.sizePercent}
                onPriceClick={() => onPrice(bid.price)}
                onSizeClick={() => onSize(bid.size)}
              />
            ))}
          </BidTable>
        </Content>
      </Body>
    </Container>
  );
};

interface OrderRowProps {
  side: 'buy' | 'sell';
  price: number;
  size: number;
  sizePercent: number;
  onSizeClick: any;
  onPriceClick: (price: number) => void | undefined;
}

const OrderRowComponent = (props: OrderRowProps) => {
  const element = useRef<HTMLDivElement>(null);

  const { market } = useMarket();

  useEffect(() => {
    // eslint-disable-next-line
    !element.current?.classList.contains('flash') &&
      element.current?.classList.add('flash');
    const id = setTimeout(
      () =>
        element.current?.classList.contains('flash') &&
        element.current?.classList.remove('flash'),
      250,
    );
    return () => clearTimeout(id);
  }, [props.price, props.size]);

  let formattedSize =
    market?.minOrderSize && !isNaN(props.size)
      ? Number(props.size).toFixed(getDecimalCount(market.minOrderSize) + 1)
      : props.size;

  let formattedPrice =
    market?.tickSize && !isNaN(props.price)
      ? Number(props.price).toFixed(getDecimalCount(market.tickSize) + 1)
      : props.price;

  return (
    <RowContainer ref={element} onClick={props.onSizeClick}>
      <OrderRowLine
        width={props.sizePercent + '%'}
        bgcolor={
          props.side === 'buy' ? 'var(--color-green)' : 'var(--color-red)'
        }
      />
      <RowContent>
        <OrderSizeContent>{formattedSize}</OrderSizeContent>
        <OrderPriceContent>{formattedPrice}</OrderPriceContent>
      </RowContent>
    </RowContainer>
  );
};

const OrderRowEqualProps = (
  prevProps: OrderRowProps,
  nextProps: OrderRowProps,
) => isEqual(prevProps, nextProps, ['price', 'size', 'sizePercent']);

const OrderRow = React.memo(OrderRowComponent, OrderRowEqualProps);

export default Orderbook;
