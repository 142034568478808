import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { DropdownArrowIcon } from './icons/DropdownArrowIcon';

export enum DropdownPositions {
  Center,
  Left,
  Right,
}

interface DropdownWrapperBodyProps {
  horizontalPosition?: DropdownPositions;
  isOpen?: boolean;
}

interface Props
  extends HTMLAttributes<HTMLDivElement>,
    DropdownWrapperBodyProps {
  body: React.ReactNode;
  header: React.ReactNode;
  shouldCloseDropdownOnClickOutside?: boolean;
}

interface State {
  isOpen: boolean;
}

const DropdownWrapper = styled.div`
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  &,
  & > svg,
  & > svg > * {
    transition: all 0.15s ease-in-out !important;
  }
`;

const DropdownWrapperHeader = styled.div<{ isOpen: boolean }>`
  user-select: none;
  display: ${(props: React.PropsWithChildren<DropdownWrapperBodyProps>) =>
    props.isOpen ? 'grid' : 'flex'};
  justify-content: ${(
    props: React.PropsWithChildren<DropdownWrapperBodyProps>,
  ) => (props.isOpen ? 'start' : 'space-between')};
  align-items: center;
  border-radius: 8px;
  padding: 6px 12px;
  ${(props: React.PropsWithChildren<DropdownWrapperBodyProps>) =>
    props.isOpen
      ? `
      grid-template-columns: auto auto auto;
      -webkit-box-pack: start;
      -webkit-box-align: center;
      cursor: pointer;
      margin-right: 3px;
      background-color: var(--color-layer-darker);
      color: var(--color-text-light);
      transition: all 0.15s ease-in-out 0s !important;
      
      &:hover {
        background-color: var(--color-layer-darker);
        color: var(--color-text-light);
      }
      & > svg {
        transform: rotate(180deg);
      }
      `
      : `
  -webkit-user-select: none;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  margin: 0 6px;

  &:hover {
    background-color: var(--color-layer-lighter);
    color: var(--color-text-light);
  }
  `}

  & > svg,
  & > svg > * {
    transition: all 0.15s ease-in-out !important;
  }

  & > svg {
    width: 8px;
    margin-left: 6px;
    margin-top: 1px;
  }
`;

const DropdownBodyWrapper = styled.div`
  position: relative;
  z-index: 1001;
  -webkit-animation: dropdown 0.2s ease-in-out;
  animation: dropdown 0.2s ease-in-out;
`;

const DropdownBodyContent = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-family: 'Relative Pro';
  font-weight: 500;
  font-feature-settings: 'zero' 0;
  position: absolute;
  top: 36px;
  left: calc(50% - 110px);
  background-color: var(--color-layer-light);
  border-radius: 8px;
  padding: 12px 20px;
  width: 120px;
`;

export class Dropdown extends React.Component<Props, State> {
  public readonly state: State = {
    isOpen: false,
  };
  private _wrapperRef: any;

  public render = () => {
    const {
      header,
      body,
      horizontalPosition = DropdownPositions.Left,
      ...restProps
    } = this.props;

    return (
      <DropdownWrapper ref={this._setWrapperRef} {...restProps}>
        <DropdownWrapperHeader
          role="button"
          onClick={this._toggleDropdown}
          isOpen={this.state.isOpen}
        >
          {header}
          <DropdownArrowIcon />
        </DropdownWrapperHeader>
        {this.state.isOpen ? (
          <DropdownBodyWrapper
            // horizontalPosition={horizontalPosition}
            onClick={this._closeDropdownBody}
          >
            <DropdownBodyContent>{body}</DropdownBodyContent>
          </DropdownBodyWrapper>
        ) : null}
      </DropdownWrapper>
    );
  };

  public componentDidMount = () => {
    document.addEventListener('mousedown', this._handleClickOutside);
  };

  public componentWillUnmount = () => {
    document.removeEventListener('mousedown', this._handleClickOutside);
  };

  public closeDropdown = () => {
    this.setState({ isOpen: false });
  };

  private readonly _setWrapperRef = (node: any) => {
    this._wrapperRef = node;
  };

  private readonly _handleClickOutside = (event: any) => {
    const { shouldCloseDropdownOnClickOutside = true } = this.props;
    if (this._wrapperRef && !this._wrapperRef.contains(event.target)) {
      if (shouldCloseDropdownOnClickOutside) {
        this.closeDropdown();
      }
    }
  };

  private readonly _toggleDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  private readonly _closeDropdownBody = () => {
    const { shouldCloseDropdownOnClickOutside = true } = this.props;
    if (shouldCloseDropdownOnClickOutside) {
      this.closeDropdown();
    }
  };
}
