import React, { HTMLAttributes } from 'react';
import { useFeeDiscountKeys } from '../../utils/markets';
import styled from 'styled-components';
import { EmptyContent } from '../common/EmptyContent';
import { percentFormat } from '../../utils/utils';
import { getFeeRates, TokenInstructions } from 'renec-serum/packages/serum';
import { PublicKey } from '@solana/web3.js';

interface Props extends HTMLAttributes<HTMLDivElement> {
  width: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #2d2d3d;
  position: relative;
  background-color: var(--color-layer-base);
  width: 100%;
  height: 100%;
`;

const Headers = styled.div`
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  background-color: var(--color-layer-base);
  padding: 0 16px;
  height: 40px;
  min-height: 40px;
  color: var(--color-text-dark);
  border-bottom: 1px solid var(--color-border-grey);
  letter-spacing: 0;
`;

const Header = styled.div<Props>`
  display: flex;
  height: 100%;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${(props: React.PropsWithChildren<Props>) => props.width};
  width: ${(props: React.PropsWithChildren<Props>) => props.width};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  overflow: scroll;
`;

const Row = styled.div`
  display: flex;
  position: relative;
  height: 48px;
  min-height: 48px;
  color: var(--color-text-base);
  border-bottom: var(--general-border);
  padding: 0 16px;
  box-sizing: border-box;
  width: max-content;
  min-width: 100%;
  margin-top: 5px;

  &:last-child {
    border-bottom: none;
  }
`;

const Cell = styled.div<Props>`
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${(props: React.PropsWithChildren<Props>) => props.width};
  width: ${(props: React.PropsWithChildren<Props>) => props.width};
`;

const FeesTable = () => {
  const [feeAccounts] = useFeeDiscountKeys();

  const tableConfig = [
    {
      title: 'Fee Tier',
      key: 'feeTier',
      width: '16%',
      index: 0,
    },
    {
      title: 'Taker',
      key: 'taker',
      width: '16%',
      index: 1,
    },
    {
      title: 'Maker',
      key: 'maker',
      width: '16%',
      index: 2,
    },
    {
      title: `Public Key`,
      key: 'pubkey',
      width: '16%',
      index: 3,
    },
    {
      title: `Balance`,
      key: 'balance',
      width: '16%',
      index: 4,
    },
    {
      title: `Mint`,
      key: 'mint',
      width: '16%',
      index: 5,
      render: (_, row) =>
        row.mint.equals(TokenInstructions.SRM_MINT)
          ? 'SRM'
          : row.mint.equals(TokenInstructions.MSRM_MINT)
          ? 'MSRM'
          : 'UNKNOWN',
    },
  ];

  const headers = tableConfig
    .sort((a, b) => a.index - b.index)
    .map((header) => (
      <Header key={header.key} width={header.width}>
        {header.title}
      </Header>
    ));

  const body = (feeAccounts || [])
    .map((account, index) => ({
      ...account,
      index,
      key: account.pubkey.toBase58(),
      taker: percentFormat.format(getFeeRates(account.feeTier).taker),
      maker: percentFormat.format(getFeeRates(account.feeTier).maker),
    }))
    .map((account, index) => {
      let content: Array<JSX.Element> = [];
      for (const header of tableConfig) {
        let cellContent = account[header.key];
        if (cellContent instanceof PublicKey) {
          cellContent = cellContent.toBase58();
          if (header.key === 'mint') {
            cellContent = account.mint.equals(TokenInstructions.SRM_MINT)
              ? 'SRM'
              : account.mint.equals(TokenInstructions.MSRM_MINT)
              ? 'MSRM'
              : 'UNKNOWN';
          }
        }
        content.push(
          <Cell width={header.width} key={`${header.key}-${header.index}`}>
            {cellContent}
          </Cell>,
        );
      }

      content.sort((a, b) => a.props.index - b.props.index);
      return <Row key={index}>{content}</Row>;
    });

  const content = feeAccounts ? (
    <Container>
      <Headers>{headers}</Headers>
      <Body>{body}</Body>
    </Container>
  ) : (
    <EmptyContent alignAbsoluteCenter={false} text={'No fee accounts'} />
  );

  return content;
};

// TODO Should we include fee schedule table for users?
// const FeeScheduleTable = () => {
//   const dataSource = [
//     { feeTier: 0, taker: 0.0022, maker: -0.0003, token: '', balance: '' },
//     { feeTier: 1, taker: 0.002, maker: -0.0003, token: 'SRM', balance: 100 },
//     { feeTier: 2, taker: 0.0018, maker: -0.0003, token: 'SRM', balance: 1000 },
//     { feeTier: 3, taker: 0.0016, maker: -0.0003, token: 'SRM', balance: 10000 },
//     {
//       feeTier: 4,
//       taker: 0.0014,
//       maker: -0.0003,
//       token: 'SRM',
//       balance: 100000,
//     },
//     {
//       feeTier: 5,
//       taker: 0.0012,
//       maker: -0.0003,
//       token: 'SRM',
//       balance: 1000000,
//     },
//     { feeTier: 6, taker: 0.001, maker: -0.0005, token: 'MSRM', balance: 1 },
//   ];
//   const columns = [
//     {
//       title: 'Fee Tier',
//       dataIndex: 'feeTier',
//       key: 'feeTier',
//     },
//     {
//       title: 'Taker',
//       dataIndex: 'taker',
//       key: 'taker',
//       render: (feeTier, row) =>
//         percentFormat.format(getFeeRates(row.feeTier).taker),
//     },
//     {
//       title: 'Maker',
//       dataIndex: 'maker',
//       key: 'maker',
//       render: (feeTier, row) =>
//         percentFormat.format(getFeeRates(row.feeTier).maker),
//     },
//     {
//       title: 'Requirements',
//       dataIndex: 'requirements',
//       key: 'requirements',
//       render: (_, row) => (
//         <Typography>
//           {!row.balance ? 'None' : `≥ ${row.balance} ${row.token}`}
//         </Typography>
//       ),
//     },
//   ];
//   return (
//     <DataTable
//       dataSource={dataSource.map((info) => ({ ...info, key: info.feeTier }))}
//       columns={columns}
//     />
//   );
// }

export default FeesTable;
