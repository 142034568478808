import { Form, Typography } from 'antd';
import React, { useState } from 'react';
import { TextInput } from '../common';
import * as S from './styles';
import { useConnection } from '../../utils/connection';
import { useWallet } from '../../utils/wallet';
import { useMintInput } from '../useMintInput';
import { MARKETS } from 'renec-serum/packages/serum';
import { notify, useSendNotification } from '../../utils/notifications';
import { listMarket } from '../../utils/send';

const { Text } = Typography;

const NewMarketForm = () => {
  const connection = useConnection();
  const { wallet, connected } = useWallet();
  const [baseMintInput, baseMintInfo] = useMintInput(
    'baseMint',
    <Text>
      Base Token Mint Address (e.g. BTC renec address:
      9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E)
    </Text>,
  );
  const [quoteMintInput, quoteMintInfo] = useMintInput(
    'quoteMint',
    <Text>
      Quote Token Mint Address (e.g. USDT renec address:
      BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4)
    </Text>,
  );
  const [lotSize, setLotSize] = useState('1');
  const [tickSize, setTickSize] = useState('0.01');
  const dexProgramId = MARKETS.find(({ deprecated }) => !deprecated)
    ?.programId!!;
  const [sendNotification] = useSendNotification();

  let baseLotSize;
  let quoteLotSize;
  if (baseMintInfo && parseFloat(lotSize) > 0) {
    baseLotSize = Math.round(10 ** baseMintInfo.decimals * parseFloat(lotSize));
    if (quoteMintInfo && parseFloat(tickSize) > 0) {
      quoteLotSize = Math.round(
        parseFloat(lotSize) *
          10 ** quoteMintInfo.decimals *
          parseFloat(tickSize),
      );
    }
  }

  const canSubmit =
    connected &&
    !!baseMintInfo &&
    !!quoteMintInfo &&
    !!baseLotSize &&
    !!quoteLotSize;

  async function onSubmit() {
    if (!canSubmit) {
      return;
    }
    if (!wallet) {
      return null;
    }

    sendNotification(
      listMarket({
        connection,
        wallet,
        baseMint: baseMintInfo.address,
        quoteMint: quoteMintInfo.address,
        baseLotSize,
        quoteLotSize,
        dexProgramId,
      }),
      {
        onSuccess: () => {},
        onError: (e: Error) => {
          console.warn(e);
          const { message } = e as Error;
          notify({
            message: 'Error listing new market',
            description: message,
            type: 'error',
          });
        },
      },
    );
  }

  return (
    <S.FormContainer>
      <S.FormTitle>List New Market</S.FormTitle>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout={'vertical'}
        onFinish={onSubmit}
      >
        {baseMintInput}
        {quoteMintInput}
        <Form.Item
          name="lotSize"
          initialValue="1"
          validateStatus={
            baseMintInfo && quoteMintInfo
              ? baseLotSize
                ? 'success'
                : 'error'
              : ''
          }
        >
          <TextInput
            label={'Minimum Order Size (Lot size in e.g. BTC)'}
            value={lotSize}
            onChange={(e) => setLotSize(e.target.value.trim())}
            type="number"
            min="0"
            step="any"
          />
        </Form.Item>
        <Form.Item
          name="tickSize"
          initialValue="0.01"
          validateStatus={
            baseMintInfo && quoteMintInfo
              ? quoteLotSize
                ? 'success'
                : 'error'
              : ''
          }
        >
          <TextInput
            value={tickSize}
            onChange={(e) => setTickSize(e.target.value.trim())}
            type="number"
            min="0"
            step="any"
            label="Tick Size (Price increment in e.g. USDT)"
          />
        </Form.Item>
        <S.FormButton type="submit" disabled={!canSubmit}>
          {connected ? 'Submit' : 'Not connected to wallet'}
        </S.FormButton>
      </Form>
    </S.FormContainer>
  );
};

export default NewMarketForm;
