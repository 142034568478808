import React, { HTMLAttributes } from 'react';
import { useMarket } from '../utils/markets';
import usePrevious from '../utils/usePrevious';
import { getDecimalCount, isEqual } from '../utils/utils';
import styled from 'styled-components';

interface Props extends HTMLAttributes<HTMLDivElement> {
  markPrice: number;
  previousMarkPrice: number;
}

const MarkPrice = React.memo(
  ({ markPrice }: { markPrice: number }) => {
    const { market } = useMarket();
    const previousMarkPrice: number = usePrevious(markPrice);

    let formattedMarkPrice =
      markPrice &&
      market?.tickSize &&
      markPrice.toFixed(getDecimalCount(market.tickSize));

    return (
      <Container>
        <Content markPrice={markPrice} previousMarkPrice={previousMarkPrice}>
          ${formattedMarkPrice}
        </Content>
      </Container>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps, ['markPrice']),
);

const Container = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  padding: 0 16px;
  width: 120px;

  &:not(:last-child):after {
    content: '';
    position: absolute;
    height: 24px;
    top: 12px;
    right: 0;
    border-right: var(--general-border);
  }

  @media (max-width: 1024px) {
    & {
      flex-direction: column;
      align-items: flex-end;
    }

    &:not(:last-child):after {
      top: 18px;
      height: 36px;
    }
  }

  @media (max-width: 768px) {
    &:not(:last-child):after {
      display: none;
    }
  }
`;

const Content = styled.span<Props>`
  font-size: 18px;
  line-height: 28px;
  color: ${(props: React.PropsWithChildren<Props>) =>
    props.markPrice > props.previousMarkPrice
      ? 'var(--color-green)'
      : props.markPrice < props.previousMarkPrice
      ? 'var(--color-red)'
      : 'var(--color-text-light)'};

  @media (max-width: 1024px) {
    & {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

export default MarkPrice;
