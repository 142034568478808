import React, { HTMLAttributes, useCallback, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DropdownArrowIcon } from '../components/common/icons/DropdownArrowIcon';
import UserInfoTable from '../components/UserInfoTable';
import {
  getTradePageUrl,
  MarketProvider,
  use24hStats,
  useMarket,
  useMarketsList,
  useMarkPrice,
} from '../utils/markets';
import PriceFormat from '../components/common/PriceFormat';
import Orderbook from '../components/Orderbook';
import RecentTrades from '../components/RecentTrades';
import TradeForm from '../components/TradeForm';
import WalletBalances from '../components/WalletBalances';
import { TVChartContainer } from '../components/TradingView';
import MarketSelector from '../components/MarketSelector';
import MarkPrice from '../components/MarkPrice';
import { getDecimalCount } from '../utils/utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  priceChange: number;
}

const TradeDesktop = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
`;

const MarketSelectorAndStats = styled.div`
  display: flex;
  min-height: 48px;
  max-height: 48px;
  border-bottom: var(--general-border);
  white-space: nowrap;

  @media (max-width: 1024px) {
    & {
      min-height: 72px;
      max-height: 72px;
      justify-content: space-between;
    }
  }
`;

const MarketStats = styled.div`
  scrollbar-width: none;
  font-family: Relative Pro;
  font-weight: 500;
  -webkit-font-feature-settings: 'zero' 0;
  font-feature-settings: 'zero' 0;
  display: flex;
  overflow: scroll;
  flex: 1 1;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  @media (max-width: 768px) {
    & {
      flex: none;
    }
  }
`;

const MarketStatsWrapper = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  scrollbar-width: none;
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1 1;
  overflow: scroll;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  @media (max-width: 768px) {
    & {
      display: none;
    }
  }
`;

const MarketStatHeader = styled.div`
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  color: #a1a5c1;
  margin-bottom: 2px;
`;

const MarketStatContentWrapper = styled.div`
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

const MarketStatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  padding: 0 16px;
  margin-top: -2px;
  width: 120px;

  &:not(:last-child):after {
    content: '';
    position: absolute;
    height: 24px;
    top: 12px;
    right: 0;
    border-right: var(--general-border);
  }

  @media (max-width: 1024px) {
    &:not(:last-child):after {
      top: 18px;
      height: 36px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 1024px) {
    & ${MarketStatHeader} {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 4px;
    }
  }

  @media (max-width: 1024px) {
    & ${MarketStatContentWrapper} {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const TradeDesktopInner = styled.div`
  display: flex;
  position: relative;
  height: calc(100% - 48px);
  width: 100%;
`;

const ChartUserTradeInfoContainer = styled.div`
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  min-width: calc(100% - 624px);
  max-width: calc(100% - 624px);
  margin-left: 20px;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  @media (max-width: 1280px) {
    & {
      min-width: calc(100% - 512px);
      max-width: calc(100% - 512px);
    }
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  height: calc(100% - 330px);
  max-height: calc(100% - 330px);
  min-height: calc(100% - 330px);
  overflow: hidden;

  @media (min-width: 1920px) {
    & {
      max-height: calc(100% - 380px);
      min-height: calc(100% - 380px);
    }
  }
`;

const UserTradeInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 330px;
  min-height: 330px;
  max-height: 330px;

  @media (min-width: 1920px) {
    & {
      height: 380px;
      min-height: 380px;
      max-height: 380px;
    }
  }
`;

const OrderbookRecentTradesContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 258px;
  max-width: 258px;
  height: 100%;
  z-index: 2;
  margin: 20px;
`;

const TradeBoxUserWalletBalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const PriceChangeContainer = styled.div<Props>`
  font-family: Relative Mono;
  font-weight: 400;
  -webkit-font-feature-settings: 'zero' 1;
  font-feature-settings: 'zero' 1;
  font-size: 12.5px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${(props: React.PropsWithChildren<Props>) =>
    props.priceChange > 0
      ? 'var(--color-green)'
      : props.priceChange < 0
      ? 'var(--color-red)'
      : 'var(--color-text-light)'};

  ${(props: React.PropsWithChildren<Props>) =>
    props.priceChange > 0
      ? `& svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }`
      : ``};

  & > svg {
    -webkit-user-select: none;
    user-select: none;
    transition: all 0.15s ease-in-out !important;
    margin-left: 10px;
  }

  & > svg svg,
  & > svg svg * {
    transition: all 0.15s ease-in-out !important;
  }
`;

const PriceChangeContent = styled.span`
  padding-left: 3px;
`;

const TradePage = () => {
  // @ts-ignore
  const { marketAddress } = useParams();
  useEffect(() => {
    if (marketAddress) {
      localStorage.setItem('marketAddress', JSON.stringify(marketAddress));
    }
  }, [marketAddress]);
  const history = useHistory();
  function setMarketAddress(address: string) {
    history.push(getTradePageUrl(address));
  }

  return (
    <MarketProvider
      marketAddress={marketAddress}
      setMarketAddress={setMarketAddress}
    >
      <TradeInner />
    </MarketProvider>
  );
};

const TradeInner = () => {
  const markPrice = useMarkPrice();
  const stats = use24hStats();
  const markets = useMarketsList();
  const { market } = useMarket();

  const changeOrderRef =
    useRef<({ size, price }: { size?: number; price?: number }) => void>();

  const componentProps = {
    onChangeOrderRef: (ref) => (changeOrderRef.current = ref),
    onPrice: useCallback(
      (price) => changeOrderRef.current && changeOrderRef.current({ price }),
      [],
    ),
    onSize: useCallback(
      (size) => changeOrderRef.current && changeOrderRef.current({ size }),
      [],
    ),
  };

  let formattedPriceChange =
    stats?.priceChange &&
    market?.tickSize &&
    Math.abs(stats?.priceChange).toFixed(getDecimalCount(market.tickSize));

  return (
    <TradeDesktop>
      <MarketSelectorAndStats>
        <MarketSelector
          markets={markets}
          shouldCloseDropdownOnClickOutside={true}
        />
        <MarketStats>
          <MarkPrice markPrice={markPrice || 0} />
          <MarketStatsWrapper>
            <MarketStatWrapper>
              <MarketStatHeader>24h Change</MarketStatHeader>
              <PriceChangeContainer priceChange={stats?.priceChange || 0}>
                <DropdownArrowIcon
                  color={
                    stats?.priceChange && stats?.priceChange > 0
                      ? 'var(--color-green)'
                      : stats?.priceChange && stats?.priceChange < 0
                      ? 'var(--color-red)'
                      : 'var(--color-text-dark)'
                  }
                />
                <PriceChangeContent>
                  ${formattedPriceChange} ({stats?.priceChangePercentage}%)
                </PriceChangeContent>
              </PriceChangeContainer>
            </MarketStatWrapper>
            <MarketStatWrapper>
              <MarketStatHeader>24h Volume</MarketStatHeader>
              <MarketStatContentWrapper>
                <PriceFormat value={stats?.volume || 0} />
              </MarketStatContentWrapper>
            </MarketStatWrapper>
            <MarketStatWrapper>
              <MarketStatHeader>High</MarketStatHeader>
              <MarketStatContentWrapper>
                <PriceFormat value={stats?.high || 0} />
              </MarketStatContentWrapper>
            </MarketStatWrapper>
            <MarketStatWrapper>
              <MarketStatHeader>Low</MarketStatHeader>
              <MarketStatContentWrapper>
                <PriceFormat value={stats?.low || 0} />
              </MarketStatContentWrapper>
            </MarketStatWrapper>
          </MarketStatsWrapper>
        </MarketStats>
      </MarketSelectorAndStats>
      <TradeDesktopInner>
        <ChartUserTradeInfoContainer>
          <ChartWrapper>
            <TVChartContainer />
          </ChartWrapper>
          <UserTradeInfoWrapper>
            <UserInfoTable />
          </UserTradeInfoWrapper>
        </ChartUserTradeInfoContainer>
        <OrderbookRecentTradesContainer>
          <Orderbook
            depth={13}
            onPrice={componentProps.onPrice}
            onSize={componentProps.onSize}
          />
          <RecentTrades />
        </OrderbookRecentTradesContainer>
        <TradeBoxUserWalletBalanceContainer>
          <TradeForm setChangeOrderRef={componentProps.onChangeOrderRef} />
          <WalletBalances />
        </TradeBoxUserWalletBalanceContainer>
      </TradeDesktopInner>
    </TradeDesktop>
  );
};

export default TradePage;
