import React, { ChangeEventHandler, FC, ReactElement } from 'react';
import { InputProps } from 'antd';
import * as S from './styles';
import { InputHelpText } from './styles';

interface Props extends InputProps {
  label?: string | ReactElement;
  value: string | number;
  helpText?: string | null;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const TextInput: FC<Props> = ({ label, helpText, ...inputProps }) => {
  return (
    <S.InputContainer>
      {label && <S.InputLabel>{label}</S.InputLabel>}
      <S.Input {...inputProps} />
      {helpText && <InputHelpText>{helpText}</InputHelpText>}
    </S.InputContainer>
  );
};

export default TextInput;
