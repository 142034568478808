import React, { useState } from 'react';
import { notification } from 'antd';
import Link from '../components/Link';
import { useSnackbar } from 'notistack';
import {
  getClusterUrlSuffix,
  useConnection,
  useConnectionConfig,
} from './connection';
import Button from '@material-ui/core/Button';
import { confirmTransaction } from './utils';

export function useSendNotification() {
  const connection = useConnection();
  const connectionConfig = useConnectionConfig();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false);

  async function sendNotification(
    signaturePromise: Promise<any>,
    {
      onSuccess,
      onError,
    }: { onSuccess: (signature: any) => void; onError: (e: Error) => void },
  ) {
    let id = enqueueSnackbar('Sending transaction...', {
      variant: 'info',
      persist: true,
    });
    setSending(true);
    try {
      let signature = await signaturePromise;
      closeSnackbar(id);
      id = enqueueSnackbar('Confirming transaction...', {
        variant: 'info',
        persist: true,
        action: (
          <ViewTransactionOnExplorerButton
            signature={signature}
            endpoint={connectionConfig.endpoint}
          />
        ),
      });
      await confirmTransaction(connection, signature);
      closeSnackbar(id);
      setSending(false);
      enqueueSnackbar('Transaction confirmed', {
        variant: 'success',
        autoHideDuration: 15000,
        action: (
          <ViewTransactionOnExplorerButton
            signature={signature}
            endpoint={connectionConfig.endpoint}
          />
        ),
      });
      if (onSuccess) {
        onSuccess(signature);
      }
    } catch (e) {
      closeSnackbar(id);
      setSending(false);
      const err = e as Error;
      console.warn(err);
      enqueueSnackbar(err.message, { variant: 'error' });
      if (onError) {
        onError(err);
      }
    }
  }

  const notification: [
    (
      signaturePromise: Promise<any>,
      {
        onSuccess,
        onError,
      }: { onSuccess: () => void; onError: (e: Error) => void },
    ) => Promise<void>,
    boolean,
  ] = [sendNotification, sending];

  return notification;
}

function ViewTransactionOnExplorerButton({ signature, endpoint }) {
  let urlSuffix = getClusterUrlSuffix(endpoint);
  return (
    <Button
      color="inherit"
      component="a"
      target="_blank"
      rel="noopener"
      href={`https://explorer.renec.foundation/tx/${signature}` + urlSuffix}
    >
      View on explorer
    </Button>
  );
}

export function useCallAsync() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return async function callAsync(
    promise,
    {
      progressMessage = 'Submitting...',
      successMessage = 'Success',
      // @ts-ignore
      onSuccess,
      // @ts-ignore
      onError,
    } = {},
  ) {
    let id = enqueueSnackbar(progressMessage, {
      variant: 'info',
      persist: true,
    });
    try {
      let result = await promise;
      closeSnackbar(id);
      if (successMessage) {
        enqueueSnackbar(successMessage, { variant: 'success' });
      }
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      console.warn(e);
      closeSnackbar(id);
      enqueueSnackbar((e as Error).message, { variant: 'error' });
      if (onError) {
        onError(e);
      }
    }
  };
}

export function notify({
  message,
  description,
  txid,
  type = 'info',
  placement = 'bottomLeft',
}: {
  message: string;
  description?: string | JSX.Element;
  txid?: string;
  type?: string;
  placement?: string;
}) {
  if (txid) {
    description = (
      <Link
        external
        to={'https://solscan.io/tx/' + txid}
        style={{ color: '#0000ff' }}
      >
        View transaction {txid.slice(0, 8)}...{txid.slice(txid.length - 8)}
      </Link>
    );
  }
  notification[type]({
    message: <span style={{ color: 'black' }}>{message}</span>,
    description: (
      <span style={{ color: 'black', opacity: 0.5 }}>{description}</span>
    ),
    placement,
    style: {
      backgroundColor: 'white',
    },
  });
}
