import React from 'react';
import NumberFormat from 'react-number-format';

const PriceFormat = (props) => {
  return (
    <NumberFormat
      value={props.value}
      prefix={'$'}
      allowEmptyFormatting={false}
      allowLeadingZeros={true}
      allowNegative={true}
      decimalSeparator={'.'}
      displayType={'text'}
      fixedDecimalScale={false}
      isNumericString={false}
      suffix={''}
      thousandSeparator={true}
      thousandsGroupStyle={'thousand'}
      type={'text'}
    />
  );
};

export default PriceFormat;
