import { nanoid } from 'nanoid';
import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useMarket } from '../utils/markets';
import { MarketInfo } from '../utils/types';
import { isEqual } from '../utils/utils';
import { DropdownArrowIcon } from './common/icons/DropdownArrowIcon';

interface Props extends HTMLAttributes<HTMLDivElement> {
  active?: boolean;
}

const Container = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 100%;
  min-width: 324px;
  max-width: 324px;
  padding: 12px 0;
  cursor: pointer;

  @media (max-width: 1024px) {
    min-width: auto;
  }
`;

const PairHeader = styled.span`
  @media (max-width: 1024px) {
    font-size: 13px;
    line-height: 16px;
    margin-top: 4px;
    color: var(--color-text-dark);
  }
`;

const DropdownHeaderContainer = styled.div`
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-right: var(--general-border);
  padding: 0 20px;

  &,
  & > span {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1024px) {
    & ${PairHeader} {
      display: flex;
      flex-direction: column;
    }
  }
`;

const CurrentPairHeader = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #a1a5c1;
`;

const TokenHeader = styled.span<Props>`
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text-dark);
  ${(props: React.PropsWithChildren<Props>) =>
    props.active ? `` : `&, &>img {height: 24px; width: 24px;}`}};

  &, &>span {
    display: flex;
    align-items: center;
  }
`;

const TokenImageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;

  & > img {
    height: 24px;
    width: 24px;
  }
`;

const Status = styled.span<Props>`
  font-size: 14px;
  line-height: 20px;
  color: #a1a5c1;

  ${(props: React.PropsWithChildren<Props>) =>
    props.active
      ? `& svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }`
      : ``};

  & > svg {
    -webkit-user-select: none;
    user-select: none;
    transition: all 0.15s ease-in-out !important;
    margin-left: 10px;
  }

  & > svg svg,
  & > svg svg * {
    transition: all 0.15s ease-in-out !important;
  }

  @media (max-width: 1024px) {
    & > span {
      display: none;
    }

    & > svg {
      margin-left: 20px;
    }
  }
`;

const BodyContainer = styled.div`
  position: relative;
  z-index: 1001;
  -webkit-animation: dropdown 0.2s ease-in-out;
  animation: dropdown 0.2s ease-in-out;
`;

const Body = styled.div`
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  top: 11px;
  background-color: var(--color-layer-base);
  padding: 12px 20px;
  min-width: 324px;
  max-width: 324px;
  overflow-y: auto;
`;

const MarketSelector = ({
  markets,
  shouldCloseDropdownOnClickOutside,
}: {
  markets: Array<MarketInfo>;
  shouldCloseDropdownOnClickOutside?: boolean;
}) => {
  const [isActive, setIsActive] = useState(false);
  const { market, setMarketAddress } = useMarket();
  const containerRef = useRef<HTMLDivElement>(null);

  const onSetMarketAddress = (address: string) => {
    setMarketAddress(address);
  };

  const extractBase = (a) => a.split('/')[0];
  const extractQuote = (a) => a.split('/')[1];

  const selectedMarket = markets.find(
    (proposedMarket) =>
      market?.address && proposedMarket.address.equals(market.address),
  );

  const body = markets
    .sort((a, b) =>
      extractQuote(a.name) === 'USDT' && extractQuote(b.name) !== 'USDT'
        ? -1
        : extractQuote(a.name) !== 'USDT' && extractQuote(b.name) === 'USDT'
        ? 1
        : 0,
    )
    .sort((a, b) =>
      extractBase(a.name) < extractBase(b.name)
        ? -1
        : extractBase(a.name) > extractBase(b.name)
        ? 1
        : 0,
    )
    .map(({ address, name }) => (
      <Row
        key={nanoid()}
        name={name}
        onClick={() => onSetMarketAddress(address.toBase58())}
      />
    ));

  useEffect(() => {
    window.addEventListener('mousedown', _handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', _handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDropdown = () => {
    setIsActive(false);
  };

  const _toggleDropdown = () => {
    setIsActive(!isActive);
  };

  const _handleClickOutside = (event: any) => {
    if (containerRef && !containerRef.current?.contains(event.target)) {
      if (shouldCloseDropdownOnClickOutside) {
        closeDropdown();
      }
    }
  };

  const _closeDropdownBody = () => {
    if (shouldCloseDropdownOnClickOutside) {
      closeDropdown();
    }
  };

  return (
    <Container ref={containerRef} role="button">
      <DropdownHeaderContainer onClick={_toggleDropdown}>
        <TokenHeader active={isActive}>
          {isActive ? (
            <CurrentPairHeader>Select Market</CurrentPairHeader>
          ) : (
            <>
              <TokenImageHeader></TokenImageHeader>
              <TokenHeader key={selectedMarket?.address.toBase58()}>
                {selectedMarket?.name}
              </TokenHeader>
            </>
          )}
        </TokenHeader>
        <Status active={isActive}>
          {isActive ? <span>Tap to close</span> : <span>All markets</span>}
          <DropdownArrowIcon />
        </Status>
      </DropdownHeaderContainer>
      {isActive ? (
        <BodyContainer onClick={_closeDropdownBody}>
          <Body>{body}</Body>
        </BodyContainer>
      ) : null}
    </Container>
  );
};

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: 100%;
`;
const RowName = styled.div`
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

interface RowProps {
  name: string;
  onClick: () => void;
}

const RowComponent = (props: RowProps) => {
  return (
    <RowContainer onClick={props.onClick}>
      <RowName>{props.name}</RowName>
    </RowContainer>
  );
};

const RowEqualProps = (prevProps: RowProps, nextProps: RowProps) =>
  isEqual(prevProps, nextProps, ['address', 'name']);

const Row = React.memo(RowComponent, RowEqualProps);

export default MarketSelector;
