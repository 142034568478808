import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tuple from 'immutable-tuple';
import { useSendConnection } from '../utils/connection';
import { refreshCache } from '../utils/fetch-loop';
import {
  useBalances,
  useLocallyStoredFeeDiscountKey,
  useMarket,
  useMarkPrice,
  useSelectedBaseCurrencyAccount,
  useSelectedQuoteCurrencyAccount,
} from '../utils/markets';
import { notify, useSendNotification } from '../utils/notifications';
import { getUnixTs, placeOrder } from '../utils/send';
import { Balances, OrderSide } from '../utils/types';
import { useWallet } from '../utils/wallet';
import { getDecimalCount, roundToDecimal } from '../utils/utils';
import { TradeInput } from './common';

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 20px 24px;
`;

const OrderActions = styled.div`
  font-family: Relative Pro;
  font-weight: 500;
  font-feature-settings: 'zero' 0;
  user-select: none;
  display: flex;
  position: relative;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 14px;
  background-color: var(--color-layer-dark);
  cursor: pointer;
`;

const TradeActionButton = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  flex: 0 0 50%;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 5px;
  transition: color 0.2s ease-in-out;
  z-index: 1;

  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const BuyButton = styled(TradeActionButton)<Partial<TradeButtonProps>>`
  background-color: ${(
    props: React.PropsWithChildren<Partial<TradeButtonProps>>,
  ) => (props.side === OrderSide.Buy ? 'var(--color-green)' : 'inherit')};
`;

const SellButton = styled(TradeActionButton)<Partial<TradeButtonProps>>`
  background-color: ${(
    props: React.PropsWithChildren<Partial<TradeButtonProps>>,
  ) => (props.side === OrderSide.Sell ? 'var(--color-red)' : 'inherit')};
`;

const OrderTypes = styled.div`
  font-size: 14px;
  line-height: 18px;
  display: flex;
  color: #6f6e84;
  background-color: #1c1c28;
  max-height: 44px;
  min-height: 44px;
  width: 100%;
`;

const OrderType = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100%;
  padding: 0 24px;
  border-right: var(--general-border);
  white-space: nowrap;
  padding: 0 12px;

  color: var(--color-text-light);
  flex: 0 0 33.3333%;

  :last-child {
    border-right: none;
  }
`;

const TradeButtonContainer = styled.div`
  font-family: Relative Pro;
  font-weight: 500;
  -webkit-font-feature-settings: 'zero' 0;
  font-feature-settings: 'zero' 0;
  border-radius: 8px;
  background: #171722;
`;

const TradeButton = styled.button`
  font-family: 'Relative Pro';
  font-weight: 500;
  font-feature-settings: 'zero' 0;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: var(--button-background-color, var(--color-layer-lighter));
  color: var(--button-text-color, var(--color-text-base));
  cursor: pointer;
  border: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  height: 40px;
  min-height: 40px;
  border-radius: 8px;
  padding: 0 16px;
  --button-background-color: ${(
    props: React.PropsWithChildren<TradeButtonProps>,
  ) =>
    props.side === OrderSide.Buy ? 'var(--color-green)' : 'var(--color-red)'};
  --button-active-background-color: rgba(63, 182, 139, 0.8);
  --button-text-color: var(--color-text-light);

  ${(props: React.PropsWithChildren<TradeButtonProps>) =>
    props.disabled
      ? `cursor: not-allowed;
  --button-background-color: var(--color-layer-lighter);
  --button-active-background-color: var(--color-layer-lighter);
  --button-text-color: var(--color-text-dark);
  --button-active-text-color: var(--color-text-dark);
  --button-hover-filter: none;`
      : ''}
`;

interface TradeButtonProps {
  side: OrderSide;
  disabled: boolean;
}

const TradeForm = ({
  style,
  setChangeOrderRef,
}: {
  style?: any;
  setChangeOrderRef?: (
    ref: ({ size, price }: { size?: number; price?: number }) => void,
  ) => void;
}) => {
  const [side, setSide] = useState<OrderSide>(OrderSide.Buy);
  const [price, setPrice] = useState<number | undefined>(undefined);
  const [baseSize, setBaseSize] = useState<number | undefined>(undefined);
  const markPrice = useMarkPrice();
  const { wallet, connected } = useWallet();
  const sendConnection = useSendConnection();
  const balances = useBalances();
  const { baseCurrency, quoteCurrency, market } = useMarket();
  const [sendNotification, sending] = useSendNotification();
  const baseCurrencyAccount = useSelectedBaseCurrencyAccount();
  const quoteCurrencyAccount = useSelectedQuoteCurrencyAccount();
  const baseCurrencyBalances =
    balances && balances.find((b) => b.coin === baseCurrency);
  const quoteCurrencyBalances =
    balances && balances.find((b) => b.coin === quoteCurrency);
  const { storedFeeDiscountKey: feeDiscountKey } =
    useLocallyStoredFeeDiscountKey();

  const publicKey = wallet?.publicKey;

  let sizeDecimalCount =
    market?.minOrderSize && getDecimalCount(market.minOrderSize);
  let priceDecimalCount = market?.tickSize && getDecimalCount(market.tickSize);

  useEffect(() => {
    setChangeOrderRef && setChangeOrderRef(doChangeOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setChangeOrderRef]);

  useEffect(() => {
    const warmUpCache = async () => {
      try {
        if (!wallet || !publicKey || !market) {
          console.log(`Skipping refreshing accounts`);
          return;
        }
        const startTime = getUnixTs();
        console.log(`Refreshing accounts for ${market.address}`);
        await market?.findOpenOrdersAccountsForOwner(sendConnection, publicKey);
        await market?.findBestFeeDiscountKey(sendConnection, publicKey);
        const endTime = getUnixTs();
        console.log(
          `Finished refreshing accounts for ${market.address} after ${
            endTime - startTime
          }`,
        );
      } catch (e) {
        console.log(`Encountered error when refreshing trading accounts: ${e}`);
      }
    };
    warmUpCache();
    const id = setInterval(warmUpCache, 30_000);
    return () => clearInterval(id);
  }, [market, sendConnection, wallet, publicKey]);

  const onSetBaseSize = (baseSize: number | undefined) => {
    setBaseSize(baseSize);
    if (!baseSize) {
      return;
    }
    let usePrice = price || markPrice;
    if (!usePrice) {
      return;
    }
  };

  const doChangeOrder = ({
    size,
    price,
  }: {
    size?: number;
    price?: number;
  }) => {
    const formattedSize = size && roundToDecimal(size, sizeDecimalCount);
    const formattedPrice = price && roundToDecimal(price, priceDecimalCount);
    formattedSize && onSetBaseSize(formattedSize);
    formattedPrice && setPrice(formattedPrice);
  };

  async function onSubmit() {
    if (!price) {
      console.warn('Missing price');
      notify({
        message: 'Missing price',
        type: 'error',
      });
      return;
    } else if (!baseSize) {
      console.warn('Missing size');
      notify({
        message: 'Missing size',
        type: 'error',
      });
      return;
    }

    if (!wallet) {
      return null;
    }

    sendNotification(
      placeOrder({
        side: side,
        price,
        size: baseSize,
        orderType: 'limit',
        market,
        connection: sendConnection,
        wallet,
        baseCurrencyAccount: baseCurrencyAccount?.pubkey,
        quoteCurrencyAccount: quoteCurrencyAccount?.pubkey,
        baseCurrency: baseCurrency!!,
        quoteCurrency: quoteCurrency!!,
        feeDiscountPubkey: feeDiscountKey,
      }),
      {
        onSuccess: () => {
          refreshCache(tuple('getTokenAccounts', wallet, connected));
          setPrice(undefined);
          onSetBaseSize(undefined);
        },
        onError: (e: Error) => {
          console.warn(e);
          const { message } = e as Error;
          notify({
            message: 'Error placing order',
            description: message,
            type: 'error',
          });
        },
      },
    );
  }

  return (
    <Container>
      <OrderActions>
        <BuyButton
          role="button"
          side={side}
          onClick={() => setSide(OrderSide.Buy)}
        >
          Buy
        </BuyButton>
        <SellButton
          role="button"
          side={side}
          onClick={() => setSide(OrderSide.Sell)}
        >
          Sell
        </SellButton>
      </OrderActions>
      <OrderTypes>
        <OrderType>Limit Order</OrderType>
      </OrderTypes>
      <TradeInput
        label="Limit Price"
        value={price}
        tabIndex={0}
        inputMode="decimal"
        inputToken={quoteCurrency}
        onChange={(e) =>
          setPrice(
            isNaN(parseFloat(e.currentTarget.value))
              ? undefined
              : parseFloat(e.currentTarget.value),
          )
        }
      />
      <TradeInput
        label="Amount"
        value={baseSize}
        tabIndex={0}
        inputMode="decimal"
        inputToken={baseCurrency}
        onChange={(e) =>
          setBaseSize(
            isNaN(parseFloat(e.currentTarget.value))
              ? undefined
              : parseFloat(e.currentTarget.value),
          )
        }
      />
      <TradeButtonContainer>
        <TradeButton
          onClick={onSubmit}
          side={side}
          disabled={
            !connected ||
            sending ||
            !(price && (price as number)) ||
            !(baseSize && (baseSize as number)) ||
            (!!price && price <= 0) ||
            (!!baseSize && baseSize <= 0) ||
            ((baseCurrencyBalances as Balances) &&
              (baseCurrencyBalances?.wallet as number) <= 0 &&
              side === OrderSide.Buy) ||
            ((quoteCurrencyBalances as Balances) &&
              (quoteCurrencyBalances?.wallet as number) <= 0 &&
              side === OrderSide.Sell)
          }
        >
          Place limit order
        </TradeButton>
      </TradeButtonContainer>
    </Container>
  );
};

export default TradeForm;
