import React, { Suspense } from 'react';
import { ConnectionProvider } from './utils/connection';
import { WalletProvider } from './utils/wallet';
import { GlobalStyle } from './global_style';
import ErrorBoundary from './components/ErrorBoundary';
import { Routes } from './routes';
import { PreferencesProvider } from './utils/preferences';
import { ReferrerProvider } from './utils/referrer';
import { SnackbarProvider } from 'notistack';

export default function App() {
  return (
    <Suspense>
      <GlobalStyle />
      <ErrorBoundary>
        <SnackbarProvider maxSnack={3}>
          <ConnectionProvider>
            <ReferrerProvider>
              <WalletProvider>
                <PreferencesProvider>
                  <Suspense>
                    <Routes />
                  </Suspense>
                </PreferencesProvider>
              </WalletProvider>
            </ReferrerProvider>
          </ConnectionProvider>
        </SnackbarProvider>
      </ErrorBoundary>
    </Suspense>
  );
}
