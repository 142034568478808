import React, { useState } from 'react';
import { useMarket } from '../../utils/markets';
import styled from 'styled-components';
import OrdersTable from './OrdersTable';
import RecentTradeHistory from './RecentTradeHistory';
import BalancesTable from './BalancesTable';
import FeesTable from './FeesTable';

const UserInfoTabsContainer = styled.div`
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 44px;
  padding-right: 12px;

  &,
  & svg,
  & svg * {
    transition: all 0.15s ease-in-out !important;
  }

  @media (max-width: 1024px) {
    & {
      padding-right: 0;
    }
  }
`;

const UserInfoTabSwitch = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  color: #a1a5c1;
  background-color: var(--color-layer-base);
  max-height: 44px;
  min-height: 44px;
  width: 100%;

  @media (max-width: 1024px) {
    & {
      scrollbar-width: none;
      font-size: 24px;
      line-height: 30px;
      padding: 0 20px 0 8px;
      max-height: 72px;
      min-height: 72px;
      justify-content: center;
      overflow: scroll;
    }

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  @media (max-width: 768px) {
    & {
      justify-content: flex-start;
    }
  }
`;

const UserInfoTabSwitchButton = styled.div`
  ${(props) =>
    props.active
      ? `
  background-color: var(--color-layer-dark);
  color: #ffffff;
  border-bottom: 2px solid var(--color-purple);

@media (max-width: 1024px) {
  & {
      background-color:inherit;
  }
}

&>svg path {
  fill: var(--color-text-light);
}`
      : ``};
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100%;
  padding: 0 24px;
  cursor: pointer;
  white-space: nowrap;

  &,
  & svg,
  & svg * {
    transition: all 0.15s ease-in-out !important;
  }

  & > svg {
    margin-top: 2px;
    margin-left: 6px;
  }

  @media (max-width: 1024px) {
    & {
      border: none;
      padding: 0 12px;
    }
  }
`;

const UserInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 44px);

  @media (max-width: 1024px) {
    border: none;
    padding-top: 4px;
    height: calc(100% - 72px);
  }
`;

export default function Index() {
  const ORDERS_TAB = 'orders';
  const FILLS_TAB = 'fills';
  const BALANCES_TAB = 'balances';
  const FEES_TAB = 'fees';
  const { market } = useMarket();
  const [currentTab, setCurrentTab] = useState(ORDERS_TAB);

  const _switchTabs = (e) => {
    const switchTab = e.currentTarget.id;
    setCurrentTab(switchTab);
  };

  return (
    <>
      <UserInfoTabsContainer>
        <UserInfoTabSwitch>
          <UserInfoTabSwitchButton
            role="button"
            tabindex={0}
            id={ORDERS_TAB}
            active={currentTab === ORDERS_TAB}
            onClick={_switchTabs}
          >
            Open Orders
          </UserInfoTabSwitchButton>
          <UserInfoTabSwitchButton
            role="button"
            tabindex={0}
            id={FILLS_TAB}
            active={currentTab === FILLS_TAB}
            onClick={_switchTabs}
          >
            Recent Trade History
          </UserInfoTabSwitchButton>
          <UserInfoTabSwitchButton
            role="button"
            tabindex={0}
            id={BALANCES_TAB}
            active={currentTab === BALANCES_TAB}
            onClick={_switchTabs}
          >
            Balances
          </UserInfoTabSwitchButton>
          <UserInfoTabSwitchButton
            role="button"
            tabindex={0}
            id={FEES_TAB}
            active={currentTab === FEES_TAB}
            onClick={_switchTabs}
          >
            Fee Discounts
          </UserInfoTabSwitchButton>
        </UserInfoTabSwitch>
      </UserInfoTabsContainer>
      <UserInfoContent>
        {currentTab === ORDERS_TAB ? (
          <OrdersTable />
        ) : currentTab === FILLS_TAB ? (
          <RecentTradeHistory />
        ) : currentTab === BALANCES_TAB ? (
          <BalancesTable />
        ) : currentTab === FEES_TAB &&
          market &&
          market.supportsSrmFeeDiscounts ? (
          <FeesTable />
        ) : null}
      </UserInfoContent>
    </>
  );
}
