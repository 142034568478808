import React, { useEffect } from 'react';
import { useReferrer } from '../utils/referrer';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { notify } from '../utils/notifications';
import styled from 'styled-components';
import Header from './Header';

const Layout = styled.div`
  display: grid;
  grid-template:
    'Header' auto
    'Main' 1fr;
  height: 100%;
`;

const Main = styled.main`
  grid-area: Main / Main / Main / Main;
  min-height: 0px;
  display: flex;
`;

export default function BasicLayout({ children }) {
  const { refCode, setRefCode, allowRefLink } = useReferrer();
  const { search } = useLocation();
  const parsed = queryString.parse(search);

  useEffect(() => {
    if (!!parsed.refCode && parsed.refCode !== refCode && allowRefLink) {
      notify({ message: `New referrer ${parsed.refCode} added` });
      setRefCode(parsed.refCode);
    }
  }, [parsed, refCode, setRefCode, allowRefLink]);

  return (
    <React.Fragment>
      <Layout>
        <Header />
        <Main>{children}</Main>
      </Layout>
    </React.Fragment>
  );
}
