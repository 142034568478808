import React from 'react';
import styled from 'styled-components';
import { useBalances, useMarket } from '../utils/markets';
import { Balances } from '../utils/types';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const Title = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  user-select: none;
  color: var(--color-text-light);
  max-height: 44px;
  min-height: 44px;

  @media (max-width: 1024px) {
    scrollbar-width: none;
    font-size: 24px;
    line-height: 30px;
    padding: 0 20px 0 8px;
    max-height: 72px;
    min-height: 72px;
    justify-content: center;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
`;

const Body = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top: var(--general-border);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 28px;
`;

const HeaderText = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a1a5c1;
  justify-content: space-between;
  padding: 10px 0;
`;

const AssetHeader = styled(HeaderText)`
  text-align: center;
  flex: 0 0 20%;
  padding-right: 5px;
  padding-left: 15px;
`;

const BalanceHeader = styled(HeaderText)`
  text-align: center;
  flex: 0 0 10%;
`;

const UnsettledBalanceHeader = styled(HeaderText)`
  text-align: center;
  flex: 0 0 20%;
  padding-right: 30px;
  margin-top: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: Relative Mono;
  font-weight: 400;
  -webkit-font-feature-settings: 'zero' 1;
  font-feature-settings: 'zero' 1;
  font-size: 12px;
  line-height: 16px;
  color: #c3c2d4;
  text-align: right;
  flex: 1 1 32%;
  padding-right: 12px;
  z-index: 2;
  border-bottom: var(--general-border);
`;

const BalanceRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 3;
`;

const Cell = styled.div`
  font-family: Relative Mono;
  font-weight: 400;
  -webkit-font-feature-settings: 'zero' 1;
  font-feature-settings: 'zero' 1;
  flex: 0 0 40%;
  text-align: right;
  text-transform: uppercase;
`;

const AssetContent = styled(Cell)`
  text-align: center;
  flex: 0 0 20%;
  padding-right: 5px;
  padding-left: 15px;
`;

const BalanceContent = styled(Cell)`
  text-align: center;
  flex: 0 0 10%;
`;

const UnsettledBalanceContent = styled(Cell)`
  text-align: center;
  flex: 0 0 20%;
  padding-right: 30px;
`;

interface BalanceRowProps {
  currency: string;
  balances: Balances;
}

const BalanceRow = (props: BalanceRowProps) => {
  return (
    <BalanceRowContainer>
      <AssetContent>{props.currency}</AssetContent>
      <BalanceContent>{props.balances && props.balances.wallet}</BalanceContent>
      <UnsettledBalanceContent>
        {props.balances && props.balances.unsettled}
      </UnsettledBalanceContent>
    </BalanceRowContainer>
  );
};

const WalletBalances = () => {
  const { baseCurrency, quoteCurrency, market } = useMarket();
  const balances = useBalances();
  const baseCurrencyBalances =
    balances && balances.find((b) => b.coin === baseCurrency);
  const quoteCurrencyBalances =
    balances && balances.find((b) => b.coin === quoteCurrency);

  const formattedBalances: [
    string | undefined,
    Balances | undefined,
    string,
    string | undefined,
  ][] = [
    [
      baseCurrency,
      baseCurrencyBalances,
      'base',
      market?.baseMintAddress.toBase58(),
    ],
    [
      quoteCurrency,
      quoteCurrencyBalances,
      'quote',
      market?.quoteMintAddress.toBase58(),
    ],
  ];

  return (
    <Container>
      <Title>Wallet Balances</Title>
      <Body>
        <Header>
          <AssetHeader>Asset</AssetHeader>
          <BalanceHeader>Balance</BalanceHeader>
          <UnsettledBalanceHeader>Unsettled Balance</UnsettledBalanceHeader>
        </Header>
        <Content>
          {formattedBalances.map(
            ([currency, balances, baseOrQuote, mint], index) => (
              <BalanceRow
                key={index}
                currency={currency!!}
                balances={balances!!}
              />
            ),
          )}
        </Content>
      </Body>
    </Container>
  );
};

export default WalletBalances;
