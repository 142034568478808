import React, { ChangeEventHandler, FC } from 'react';
import { InputProps } from 'antd';

import * as S from './styles';

interface Props extends InputProps {
  label: string;
  value: string | number | undefined;
  inputToken?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const TradeInput: FC<Props> = ({
  label,
  value,
  onChange,
  inputToken,
  ...restProps
}) => {
  return (
    <S.InputContainer>
      <S.InputHeader>{label}</S.InputHeader>
      <S.BaseInput
        {...{
          value,
          onChange,
          ...restProps,
        }}
      />
      {inputToken && (
        <S.InputTokenContainer>
          <S.InputToken>{inputToken}</S.InputToken>
        </S.InputTokenContainer>
      )}
    </S.InputContainer>
  );
};

export default TradeInput;
