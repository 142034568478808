import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import BasicLayout from './components/BasicLayout';
import { getTradePageUrl } from './utils/markets';
import TradePage from './pages/TradePage';
import ListNewMarketPage from './pages/ListNewMarketPage';

export function Routes() {
  return (
    <>
      <HashRouter basename={'/'}>
        <BasicLayout>
          <Switch>
            <Route exact path="/">
              <Redirect to={getTradePageUrl()} />
            </Route>
            <Route exact path="/market/:marketAddress">
              <TradePage />
            </Route>
            {/* <Route exact path="/orders" component={OpenOrdersPage} />
            <Route exact path="/balances" component={BalancesPage} />
            <Route exact path="/convert" component={ConvertPage} /> */}
            <Route exact path="/list-new-market">
              <ListNewMarketPage />
            </Route>
            {/* <Route exact path="/pools">
              <PoolListPage />
            </Route>
            <Route exact path="/pools/new">
              <NewPoolPage />
            </Route>
            <Route exact path="/pools/:poolAddress">
              <PoolPage />
            </Route> */}
          </Switch>
        </BasicLayout>
      </HashRouter>
    </>
  );
}
