import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { useSendConnection } from '../../utils/connection';
import { useOpenOrders } from '../../utils/markets';
import { notify } from '../../utils/notifications';
import { cancelOrder } from '../../utils/send';
import { useWallet } from '../../utils/wallet';
import { EmptyContent } from '../common/EmptyContent';

interface Props extends HTMLAttributes<HTMLDivElement> {
  width?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #2d2d3d;
  position: relative;
  background-color: var(--color-layer-base);
  width: 100%;
  height: 100%;

  &._1cei9iHpQdgfc__F4l4-Bm {
    border: 1px solid #2d2d3d;
  }
`;

const HeaderWrapper = styled.div`
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  background-color: var(--color-layer-dark);
  padding: 0 16px;
  height: 40px;
  min-height: 40px;
  color: var(--color-text-dark);
  border-bottom: 1px solid #2d2d3d;
  letter-spacing: 0;
`;

const HeaderContentWrapper = styled.div<Props>`
  display: flex;
  height: 100%;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${(props: React.PropsWithChildren<Props>) => props.width};
  width: ${(props: React.PropsWithChildren<Props>) => props.width};
`;

const HeaderContent = styled.div`
  display: flex;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  overflow: scroll;
`;

const Row = styled.div`
  display: flex;
  position: relative;
  height: 48px;
  min-height: 48px;
  color: #c3c2d4;
  border-bottom: var(--general-border);
  padding: 0 16px;
  box-sizing: border-box;
  width: max-content;
  min-width: 100%;

  &:last-child {
    border-bottom: none;
  }
`;

const Cell = styled.div<Props>`
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${(props: React.PropsWithChildren<Props>) => props.width};
  width: ${(props: React.PropsWithChildren<Props>) => props.width};
`;

const CancelButton = styled(Button)`
  color: #f23b69;
  border: 1px solid #f23b69;
`;

const OrdersTable = (onCancelSuccess?: () => void) => {
  const orders = useOpenOrders();
  let { wallet } = useWallet();
  let connection = useSendConnection();

  const [cancelId, setCancelId] = useState(null);

  async function cancel(order) {
    setCancelId(order?.orderId);
    try {
      if (!wallet) {
        return null;
      }

      await cancelOrder({
        order,
        market: order.market,
        connection,
        wallet,
      });
    } catch (e) {
      const { message } = e as Error;
      notify({
        message: 'Error cancelling order',
        description: message,
        type: 'error',
      });
      return;
    } finally {
      setCancelId(null);
    }
    onCancelSuccess && onCancelSuccess();
  }

  const tableConfig = [
    {
      title: 'Market',
      key: 'marketName',
      width: '25%',
      index: 0,
    },
    {
      title: 'Side',
      key: 'side',
      width: '25%',
      index: 1,
    },
    {
      title: 'Size',
      key: 'size',
      width: '25%',
      index: 2,
    },
    {
      title: 'Price',
      key: 'price',
      width: '25%',
      index: 3,
    },
  ];

  const headers = tableConfig
    .sort((a, b) => a.index - b.index)
    .map((header) => (
      <HeaderContentWrapper key={header.key} width={header.width}>
        <HeaderContent>{header.title}</HeaderContent>
      </HeaderContentWrapper>
    ));

  const body =
    orders &&
    orders.map((order, index) => {
      let content: Array<JSX.Element> = [];
      for (const header of tableConfig) {
        content.push(
          <Cell width={header.width} key={`${header.key}-${header.index}`}>
            {order[header.key]}
          </Cell>,
        );
      }
      content.push(
        <Cell key={`cancel-button-${index}`}>
          <CancelButton
            icon={<DeleteOutlined />}
            onClick={() => cancel(order)}
            loading={cancelId + '' === order?.orderId + ''}
          >
            Cancel
          </CancelButton>
        </Cell>,
      );
      content.sort((a, b) => a.props.index - b.props.index);
      return <Row key={index}>{content}</Row>;
    });

  const content = orders ? (
    <Wrapper>
      <HeaderWrapper>{headers}</HeaderWrapper>
      <BodyWrapper>{body}</BodyWrapper>
    </Wrapper>
  ) : (
    <EmptyContent alignAbsoluteCenter={false} text={'No open orders'} />
  );

  return content;
};

export default OrdersTable;
