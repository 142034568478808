import styled from 'styled-components';
import { FC } from 'react';
import { Input, InputProps } from 'antd';

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 14px;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-right: 6px;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  min-height: 40px;
  display: flex;
  font-family: Relative Mono;
  font-weight: 400;
  border: 2px solid var(--color-border-grey);
  font-feature-settings: 'zero' 1;
  justify-content: flex-end;
  border-radius: 6px;
  padding-left: 12px;
  flex: 1 1;
  overflow: hidden;
  background-color: #232334;
`;

export const InputHeader = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.06em;
  width: 40%;
`;

export const InputTokenContainer = styled.div`
  display: grid;
  align-self: center;
  padding-right: 12px;
`;

export const InputToken = styled.div`
  margin-left: 4px;
  padding: 0 3px 0 4px;
  margin-top: -2px;
  margin-bottom: -2px;
  font-family: Relative Pro;
  font-weight: 500;
  font-feature-settings: 'zero' 0;
  font-size: 10px;
  line-height: 16px;
  display: inline-flex;
  background-color: var(--color-layer-lighter);
  border-radius: 2px;
  color: var(--color-text-base);
  text-transform: uppercase;
  letter-spacing: 0.06em;

  &,
  & svg,
  & svg * {
    transition: all 0.15s ease-in-out !important;
  }
`;

export const BaseInput: FC<InputProps> = styled(Input)`
  font-size: 16px;
  line-height: 20px;
  font-family: Relative Mono;
  font-weight: 400;
  -webkit-font-feature-settings: 'zero' 1;
  font-feature-settings: 'zero' 1;
  background-color: #232334;
  padding: 0;
  width: 100%;
  border: 0;
  color: #f7f7f7;
  padding: 0;
`;
