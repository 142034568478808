import styled from 'styled-components';
import { Input as BaseInput, InputProps } from 'antd';
import React from 'react';

export const InputContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

export const InputLabel = styled.label`
  font-size: 14px;
  display: block;
  padding-bottom: 8px;
`;

export const InputHelpText = styled.p`
  padding-top: 4px;
  font-size: 13px;
`;

export const Input: React.FC<InputProps> = styled(BaseInput)`
  padding: 4px 12px;
  border: var(--general-border);
  border-radius: 2px;
  background: transparent;
  color: var(--color-text-light);
  line-height: 22px;

  &:focus {
    border-color: var(--color-yellow);
  }
`;
