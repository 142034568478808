import styled from 'styled-components';

export const FormContainer = styled.div`
  padding: 20px;
  margin: auto;
  width: 100%;
  max-width: 800px;
  background: rgb(26, 32, 41);
`;

export const FormTitle = styled.h1`
  color: var(--color-yellow);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 12px;
`;

export const FormButton = styled.button`
  background: transparent;
  color: var(--color-text-light);
  border: var(--general-border);
  border-radius: 2px;
  padding: 4px 16px;
  line-height: 22px;
  margin: 24px 0;

  :disabled {
    color: var(--color-text-dark);
    background-color: var(--color-layer-darker);
  }
`;
