import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface Props extends HTMLAttributes<HTMLDivElement> {
  width: number;
}

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalContainer = styled.div<Props>`
  width: ${(props: React.PropsWithChildren<Props>) => props.width}px;
  height: 100%;
  background: var(--color-layer-base);
  z-index: 10;
  border-radius: 16px;
`;

const ModalHeader = styled.div`
  height: 50px;
  background: var(--color-layer-base);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const ModalHeading = styled.h5`
  margin: 0;
  padding: 10px;
  color: var(--color-text-light);
  font-weight: 500;
  font-size: 18px;
  text-align: center;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const CancelButton = styled.button`
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;

  :hover {
    box-shadow: none;
    transform: none;
    background: whitesmoke;
  }
`;

const WalletModal = ({
  title,
  okText,
  visible,
  onCancel,
  width,
  children,
}: {
  title: string;
  okText: string;
  visible: boolean;
  onCancel: () => void;
  width: number;
  children: React.ReactNode;
}) => {
  return (
    <Container>
      <ModalContainer width={width}>
        <ModalHeader>
          <ModalHeading>{title}</ModalHeading>
        </ModalHeader>
        {children}
        <ActionContainer>
          <CancelButton onClick={() => onCancel()}>Cancel</CancelButton>
        </ActionContainer>
      </ModalContainer>
    </Container>
  );
};

export default WalletModal;
