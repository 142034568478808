import { Button } from 'antd';
import React, { HTMLAttributes } from 'react';
import { Market, OpenOrders } from 'renec-serum/packages/serum';
import styled from 'styled-components';
import { useSendConnection } from '../../utils/connection';
import {
  getSelectedTokenAccountForMint,
  useBalances,
  useTokenAccounts,
} from '../../utils/markets';
import { notify } from '../../utils/notifications';
import { useReferrer } from '../../utils/referrer';
import { settleFunds } from '../../utils/send';
import { useWallet } from '../../utils/wallet';
import { EmptyContent } from '../common/EmptyContent';

interface Props extends HTMLAttributes<HTMLDivElement> {
  width?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #2d2d3d;
  position: relative;
  background-color: var(--color-layer-base);
  width: 100%;
  height: 100%;
`;

const Headers = styled.div`
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  background-color: var(--color-layer-base);
  padding: 0 16px;
  height: 40px;
  min-height: 40px;
  color: var(--color-text-dark);
  border-bottom: 1px solid var(--color-border-grey);
  letter-spacing: 0;
`;

const Header = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${(props: React.PropsWithChildren<Props>) => props.width};
  width: ${(props: React.PropsWithChildren<Props>) => props.width};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  overflow: scroll;
`;

const Row = styled.div`
  display: flex;
  position: relative;
  height: 48px;
  min-height: 48px;
  color: var(--color-text-base);
  border-bottom: var(--general-border);
  padding: 0 16px;
  box-sizing: border-box;
  width: max-content;
  min-width: 100%;
  margin-top: 5px;

  &:last-child {
    border-bottom: none;
  }
`;

const Cell = styled.div<Props>`
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${(props: React.PropsWithChildren<Props>) => props.width};
  width: ${(props: React.PropsWithChildren<Props>) => props.width};
`;

const BalancesTable = ({
  onSettleSuccess,
}: {
  onSettleSuccess: () => void;
}) => {
  const balances = useBalances();
  const [accounts] = useTokenAccounts();
  const connection = useSendConnection();
  const { wallet } = useWallet();
  const { usdcRef, usdtRef } = useReferrer();

  async function onSettleFunds(market: Market, openOrders: OpenOrders) {
    try {
      const baseCurrencyAccount = getSelectedTokenAccountForMint(
        accounts,
        market?.baseMintAddress,
      );
      const quoteCurrencyAccount = getSelectedTokenAccountForMint(
        accounts,
        market?.quoteMintAddress,
      );
      if (!wallet) {
        throw new Error('Cannot find wallet adapter');
      }
      if (!baseCurrencyAccount) {
        throw new Error('Cannot find base currency token');
      }
      if (!quoteCurrencyAccount) {
        throw new Error('Cannot find quote currency token');
      }
      await settleFunds({
        market,
        openOrders,
        connection,
        wallet,
        baseCurrencyAccount,
        quoteCurrencyAccount,
        usdcRef,
        usdtRef,
      });
    } catch (e) {
      const { message } = e as Error;
      notify({
        message: 'Error settling funds',
        description: message,
        type: 'error',
      });
      return;
    }
    onSettleSuccess && onSettleSuccess();
  }

  const tableConfig = [
    {
      title: 'Coin',
      key: 'coin',
      width: '20%',
      index: 0,
    },
    {
      title: 'Wallet Balance',
      key: 'wallet',
      width: '20%',
      index: 1,
    },
    {
      title: 'Orders',
      key: 'orders',
      width: '20%',
      index: 2,
    },
    {
      title: 'Unsettled',
      key: 'unsettled',
      width: '20%',
      index: 3,
    },
  ];

  const headers = tableConfig
    .sort((a, b) => a.index - b.index)
    .map((header) => (
      <Header key={header.key} width={header.width}>
        {header.title}
      </Header>
    ));

  const body = balances.map((balance, index) => {
    let content: Array<JSX.Element> = [];
    for (const header of tableConfig) {
      content.push(
        <Cell width={header.width} key={`${header.key}-${header.index}`}>
          {balance[header.key]}
        </Cell>,
      );
    }
    content.push(
      <Cell key={`settle-button-${index}`}>
        <Button
          ghost
          style={{ marginRight: 12 }}
          onClick={() => onSettleFunds(balance.market!!, balance.openOrders!!)}
        >
          Settle
        </Button>
      </Cell>,
    );
    content.sort((a, b) => a.props.index - b.props.index);
    return <Row key={index}>{content}</Row>;
  });

  const content = balances ? (
    <Container>
      <Headers>{headers}</Headers>
      <Body>{body}</Body>
    </Container>
  ) : (
    <EmptyContent alignAbsoluteCenter={false} text={'No balances'} />
  );

  return content;
};

export default BalancesTable;
